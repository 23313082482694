/* General Layout */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f6f9;
    margin: 0;
    padding: 0;
    color: #333;
}

.class-main-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px 0 20px;
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

/* Input Section */
.input-section {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    /*justify-content: center;*/
    align-items: flex-start;
    background: white;
    border-bottom: 1px solid #e0e0e0;
    flex-direction: row;
    padding: 16px 0 8px 12px;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 64px;
}

.search-box {
    width: 100%;
    max-width: 600px;
    height: 40px;
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    outline: none;
    font-size: 14px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-box:focus {
    border-color: #007BFF;
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.4);
}

.submit-button {
    background-color: #11175e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 20px;
    width: 100px;
    height: 40px;
    text-align: center;
}

.submit-button:hover {
    background-color: #11175e;
}

/* Tables and Data Display */
.table-container {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.data-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
}

.data-table th,
.data-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.data-table th {
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: bold;
    text-transform: uppercase;
    color: #555;
}

.data-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.data-table tr:hover {
    background-color: #eaf3ff;
}

/* Editable Row */
.selected-row {
    background-color: #eaf3ff;
    font-weight: bold;
}

/* Buttons */
.edit-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: #f8f9fa;
    color: #007BFF;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.3s ease;
}

.edit-button:hover {
    background-color: #007BFF;
    color: white;
}

.stop-editing-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
    transition: background-color 0.3s ease;
    width: 100px;
    height: 40px;
    text-align: center;
}

.stop-editing-btn:hover {
    background-color: #b02a37;
}

/* Headline */
.headline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #333;
    margin: 0;
}

/* Sentence Edit Container */
.sentence-edit-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    border: 1px solid #ddd;
    margin: 16px 0;
    width: 63%;
    border-radius: 4px;
    background-color: #f8f9fa;
}

.sentence-edit-container h3 {
    margin: 0 0 12px 0;
    font-size: 18px;
    color: #333;
}

/* Improved Table Styling */
.sentence_col {
    width: 500px;
    text-align: left;
    font-size: 14px;
    position: relative;
    color: #333;
}

.other_col {
    width: 100px;
    text-align: center;
    font-size: 14px;
    color: #555;
}

.tables-container {
    display: flex;
    flex-direction: row;
}

.input-section-2 {
    display: flex;
    align-content: center;
    align-items: flex-start;
}

.chart-container {
    display: flex;
    width: 100%;
    height: 200px;
}

.classifier-dropdown {
    margin-right: 10px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.treemap-container {
    display: flex;
    height: 200px;
    width: 60%;
}


.piechart-container {
    display: flex;
    height: 200px;
    width: 40%;
}

.selected-sentence-border {
    border: 2px solid #2563EB;
    border-radius: 4px;
}

.new-sentence-border {
    border-bottom: 1px solid #cccccc;
}

.sentence-border {
    border: 1px solid black;
    border-radius: 4px;
}

.left-container-border-r {
    border-right: 1px solid #cccccc;
}

.MuiMenuItem-root {
    font-size: 12px !important;
    padding: 6px 16px !important;
    border-radius: 6px !important;
    transition: background 0.2s ease-in-out;
}

.MuiMenuItem-root:hover {
    background-color: #f3f4f6 !important; /* Light gray hover */
}

.custom-menu-style {
    border-radius: 10px !important;
    padding: 8px 0 !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
    min-width: 150px !important;
}

.menu-item {
    display: flex !important;
    align-items: center !important;
    font-size: 14px !important;
    padding: 8px 16px !important;
    border-radius: 6px !important;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
}

.menu-icon {
    font-size: 16px !important; /* Same size as text */
    margin-right: 8px;
}

.no-border {
    border: none;
}

@keyframes fadeSlideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sentence-version {
    animation: fadeSlideIn 0.5s ease-in;
}

.sentence-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #4B5563; /* Elegant dark gray */
    color: white;
    font-size: 9px;
    z-index: 2;
    margin-right: 4px;
}

.sentence-container {
    min-height: 30px;
    position: relative;
}

.sentence-number.has-versions {
    background-color: #2563EB;
}


/* Line above for versioned sentences */
.sentence-number.before-line::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: #4B5563;
    transform: translateX(-50%);
    z-index: 1;
}

/* Line below for main sentences with versions and intermediate versions */
.after-line {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 14px;
    width: 1px;
    height: calc(80% + 10px);
    background-color: #4B5563;
    transform: translateX(-50%);
    z-index: 1;
}


.first-sentence .sentence-number::before {
    display: none; /* Remove line for the first sentence */
}

/* classification.css */
.custom-menu {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
}

.menu-item {

}

.menu-item:hover {
    background-color: #f0f0f0;
}



.menu-button {
    background: none;
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #4B5563 transparent; /* Track color is transparent */
}

/* Chrome, Edge, Safari */
*::-webkit-scrollbar {
    width: 4px; /* Thin scrollbar */
    border-radius: 10px; /* Rounded corners */
}

*::-webkit-scrollbar-track {
    background: transparent; /* No background for a cleaner look */
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #4B5563; /* Dark gray thumb */
    border-radius: 10px;
    border: 1px solid transparent; /* Prevent sharp edges */
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #374151; /* Slightly darker when hovered */
}

/*flex items-center text-xs border border-gray-400 rounded bg-white shadow-md transition*/
.delete-all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid rgb(223, 226, 231);
    border-radius: 16px;
    background-color: rgba(246, 247, 248, 0.5);
    font-weight: 900;
    padding: 4px 8px;
    color: black;
    height: 24px;
    gap: 4px; /* Spacing between icon and text */
    cursor: pointer;
    line-height: 1; /* Prevents unwanted extra space */
}

.delete-all-icon {
    display: flex;  /* Ensures the icon aligns properly */
    align-items: center;
    font-size: 14px !important; /* Slightly increased for better visual balance */
    vertical-align: middle;
}

.sentence-topic-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border: 1px solid rgb(223, 226, 231);
    border-radius: 16px;
    font-weight: 600;
    padding: 4px 8px;
    color: black;
    height: 24px;
    gap: 4px; /* Spacing between icon and text */
    cursor: pointer;
    line-height: 1; /* Prevents unwanted extra space */
    text-transform: uppercase;
}

.selected-proj-border {
    border: 2px solid #cccccc
}



