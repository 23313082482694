.filter-bar-container {
    display: flex;
    position: sticky;
    z-index: 10;
    top: 64px;
    background-color: #ececec;
    flex-direction: row;
    padding: 8px 0 8px 12px;
    align-items: center;
    margin-bottom: 8px
}

.empty-filter-bar-container {
    display: flex;
    position: sticky;
    z-index: 10;
    top: 64px;
    background-color: #ffffff;
    flex-direction: row;
    padding: 8px 0 8px 24px;
    align-items: center;
    /*height: 42px;*/
    margin-bottom: 8px;
}